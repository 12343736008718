// extracted by mini-css-extract-plugin
export var article = "post-preview-module--article--KX5kQ";
export var articleList = "post-preview-module--article-list--VpapC";
export var body = "post-preview-module--body--wKUdd";
export var card = "post-preview-module--card--Y6+a3";
export var image = "post-preview-module--image--mNdVE";
export var link = "post-preview-module--link--8vk3n";
export var meta = "post-preview-module--meta--1WqYP";
export var previews = "post-preview-module--previews--0925n";
export var publishDate = "post-preview-module--publishDate--8qJCf";
export var text = "post-preview-module--text--fTwWM";
export var title = "post-preview-module--title--0qwK7";